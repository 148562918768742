<div class="container">
  <mat-card class="example-card">
    <mat-card-header>
      <mat-card-title>Prüfprotokolle</mat-card-title>
      <mat-card-subtitle style="margin-bottom: 20px;">Gemäß VDE DIN EN 50678 VDE 0701:2021-02 und DIN EN 50699 VDE 0702:2021-06</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <div class="form-field fontSize20"  >
        <mat-form-field class="example-form-field fontSize20">
          <mat-label class="fontSize20">Kundenummer</mat-label>
          <input class="fontSize20"  matInput #knInput (input)="customerChanged()" type="number" [(ngModel)]="kn">
          <button matSuffix mat-icon-button aria-label="Clear" 
                  *ngIf="kn && kn > 0" (click)="clearField('kn')">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        <mat-form-field class="example-form-field fontSize20">
          <mat-label class="fontSize20"  >Ident-.Nr.</mat-label>
          <input  matInput #identInput (input)="identChanged()" type="text" [(ngModel)]="ident">
          <button matSuffix mat-icon-button aria-label="Clear" 
                  *ngIf="ident && ident.length > 0" (click)="clearField('ident')">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </mat-card-content>
    <mat-card-actions class="action-buttons">
      <button mat-raised-button color="primary" [disabled]="true" id="protokolButton" (click)="getProtokol()">Protokoll anfordern</button>
    </mat-card-actions>
  </mat-card>
</div>
