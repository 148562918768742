import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { BcSnackBarService, Destroyer } from 'bcSharedComponents';
import { ProtokolleService } from 'bcWwsWebApi';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-dguv-protocol',
  templateUrl: './dguv-protocol.component.html',
  styleUrls: ['./dguv-protocol.component.sass'],
})
export class DGUVProtocolComponent extends Destroyer implements OnInit{
  isKunde: boolean = false;
  isIdentId: boolean = false;

  pdfSrc: any;

  kn: number | null = null;
  ident: string | null = null;
  @ViewChild('knInput') knInput!: ElementRef;
  @ViewChild('identInput') identInput!: ElementRef;

  constructor(
    private _protokolleService: ProtokolleService,
    private _snackbarService: BcSnackBarService
  ) {
    super();
  }

  ngOnInit(): void {}

  customerChanged() {
    this.isKunde = this.kn !== null && !isNaN(this.kn);
    this.updateButtonState();
  }

  identChanged() {
    this.isIdentId = this.ident !== null && this.ident.trim() !== '';
    this.updateButtonState();
  }

  updateButtonState() {
    this.isKunde && this.isIdentId ? this.enableButton() : this.disableButton();
  }

  enableButton() {
    const button = document.getElementById(
      'protokolButton'
    ) as HTMLButtonElement;
    if (button) {
      button.disabled = false;
    }
  }

  disableButton() {
    const button = document.getElementById(
      'protokolButton'
    ) as HTMLButtonElement;
    if (button) {
      button.disabled = true;
    }
  }
  clearField(fieldName: string) {
    if (fieldName === 'kn') {
      this.kn = null;
      this.customerChanged();
    } else if (fieldName === 'ident') {
      this.ident = null;
      this.identChanged();
    }
  }

  getProtokol() {
    if (this.isKunde && this.isIdentId) {
      this._protokolleService
        .apiProtokolleGetPruefberichtPdfGet(
          this.kn?.toString(),
          this.ident?.toString()
        )
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (value: Blob) => {
            this.showPdf(value);
          },
          error: (error) => {
            console.error(error);
            if (error.status === 404) {
              this._snackbarService.show(
                'Kein Protokoll gefunden!',
                'Schließen',
                3000
              );
            }
            if (error.status === 429)
              this._snackbarService.show(
                'Versuchen Sie es später noch mal.',
                'Schließen',
                3000
              );
          }
        });
    }
  }

  showPdf(pdfBlob: Blob): void {
    const fileURL = URL.createObjectURL(pdfBlob);
    window.open(fileURL, '_blank');
  }
}
